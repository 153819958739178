import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const BasicVideo = makeShortcode("BasicVideo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "rulesfoundations-for-their-future"
    }}>{`Rules…foundations for their future`}</h1>
    <p>{`The teenage years are the stage between childhood and adulthood – rules that guide positive behaviour and good choices will be taken into adulthood. The ultimate goal of setting and reviewing family rules is that your teenager grows into an adult with their own personal rules that will guide their choices.`}</p>
    <BasicVideo url="https://player.vimeo.com/video/575138421" mdxType="BasicVideo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      